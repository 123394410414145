import { RECEIVE_PROFILE } from "../constants/action-types";
import { USER_PROFILE } from "../constants/local-storage";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	profile: [],
};

const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_PROFILE:
			return {
				...state,
				profile: action.profile,
			};
		default:
			return state;
	}
};

const persistConfig = {
	keyPrefix: `${USER_PROFILE}-`,
	key: USER_PROFILE,
	storage,
};

export default persistReducer(persistConfig, profileReducer);
