import { DELETE } from "../api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../lang/en/messages";
import { CART_REMOVE_ITEM } from "../../constants/api";

export const removeCartItem = (owner_id, uuid, callback, token) => {
	const REMOVE_ITEM = CART_REMOVE_ITEM.replace(":uuid", uuid);
	DELETE(
		`${REMOVE_ITEM}${owner_id !== "" ? "?owner_id=" + owner_id : ""}`,
		function (data) {
			if (data.status && data.status) {
				callback(data.status);
			} else {
				if (data.http_error) {
					toast.error(data.error);
					callback(0);
				} else {
					if (data.errors) {
						for (let i = 0; i < data.errors.length; i++) {
							toast.error(data.errors[i]);
							callback(0);
						}
					} else {
						toast.error(SOMETHING_WRONG);
						callback(0);
					}
				}
			}
		},
		token
	);
};
