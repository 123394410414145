import { findIndex } from "../utils";

/**
 * Get visible products
 *
 * @param {Array} products
 * @param {Object} param1
 * @return {Array} filtered products
 */

export const getVisibleProducts = (
	products,
	{ sortBy, main_category, category, size, brand, color, rating, searchString, shippingType, condition }
) => {
	return products.filter((item) => {
		let searchResult = false,
			mainCatResult = false,
			catResult = false,
			sizeResult = false,
			brandResult = false,
			colorResult = false,
			ratingResult = false,
			shippingTypeResult = false,
			conditionResult = false;
		if (item.stock == null) {
			item.stock = 0;
		}

		if (searchString) {
			// products search by name
			if (item.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
				searchResult = true;
			}
			if (item.shortDesc && item.shortDesc.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
				searchResult = true;
			}
		} else {
			searchResult = true;
		}

		if (main_category && main_category.length > 0) {
			for (let i = 0; i < main_category.length; i++) {
				if (item.main_category === main_category[i]) mainCatResult = true;
			}
		} else {
			mainCatResult = true;
		}

		if (category && category.length > 0) {
			for (let i = 0; i < category.length; i++) {
				if (
					-1 !== findIndex(item.category, (cat) => cat.toLowerCase() === category[i].toLowerCase()) ||
					(category[i] === "Sale" && item.discount > 0) ||
					category[i] === "All"
				)
					catResult = true;
			}
		} else {
			catResult = true;
		}

		if (size && size.length > 0) {
			for (let i = 0; i < size.length; i++) {
				if (-1 !== findIndex(item.size, (sz) => sz === size[i])) sizeResult = true;
			}
		} else {
			sizeResult = true;
		}

		if (brand && brand.length > 0) {
			for (let i = 0; i < brand.length; i++) {
				if (-1 !== findIndex(item.brands, (br) => br.toLowerCase() === brand[i].toLowerCase())) brandResult = true;
			}
		} else {
			brandResult = true;
		}

		if (shippingType && shippingType.length > 0) {
			for (let i = 0; i < shippingType.length; i++) {
				if (
					-1 !==
					findIndex(
						[item.shipping_type],
						(br) =>
							br.toLowerCase() === shippingType[i].toLowerCase() ||
							(br.toLowerCase() === "fedex_or_local_pickup" &&
								(shippingType[i].toLowerCase() === "fedex" || shippingType[i].toLowerCase() === "local_pickup_only"))
					)
				) {
					shippingTypeResult = true;
				}
			}
		} else {
			shippingTypeResult = true;
		}

		if (condition && condition.length > 0) {
			for (let i = 0; i < condition.length; i++) {
				if (-1 !== findIndex([item.condition], (br) => br.toLowerCase() === condition[i].toLowerCase())) {
					conditionResult = true;
				}
			}
		} else {
			conditionResult = true;
		}

		if (color && color.length > 0) {
			for (let i = 0; i < color.length; i++) {
				if (-1 !== findIndex(item.variants, (cl) => cl.color === color[i])) colorResult = true;
			}
		} else {
			colorResult = true;
		}

		if (rating && rating.length > 0) {
			for (let i = 0; i < rating.length; i++) {
				if (item.ratings === rating[i]) ratingResult = true;
			}
		} else {
			ratingResult = true;
		}

		return (
			searchResult &&
			mainCatResult &&
			catResult &&
			sizeResult &&
			brandResult &&
			colorResult &&
			ratingResult &&
			shippingTypeResult &&
			conditionResult
		);
	});
};

/**
 * Get featured products
 * @param {Array} products
 * @return {Array} featuredProducts
 */
export const getFeaturedProducts = (products) => {
	return products.filter((item) => true === item.featured);
};

/**
 * Get sold products
 * @param {Array} products
 * @return {Array} saleProducts
 */
export const getSaleProducts = (products) => {
	return products.filter((item) => item.discount > 0);
};

/**
 * Get new products
 * @param {Array} products
 * @return {Array} newProducts
 */
export const getNewProducts = (products) => {
	return products.filter((item) => item.new);
};

/**
 * Get deal products
 * @param {Array} products
 * @return {Array} dealProducts
 */
export const getDealProducts = (products, deal) => {
	return products.filter((item) => item.deal === deal);
};

/**
 * Get products which has top rating
 * @param {Array} products
 * @return {Array} topRatingProducts
 */
export const getTopRatingProducts = (products) => {
	return products
		.filter((product) => {
			return product.ratings > 2;
		})
		.sort((product1, product2) => {
			return product2.ratings < product1.ratings ? -1 : 1;
		});
};

/**
 * Get products which has top sale
 * @param {Array} products
 * @return {Array} topSellingProducts
 */
export const getTopSellingProducts = (products) => {
	return products.filter((item) => true === item.top);
};

/**
 * Get products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Array} filteredProducts
 */
export const getProductsByCategory = (products, category) => {
	if (category === "All") return products;

	if (-1 !== category.indexOf("&")) {
		category = category.split(" & ");
	}

	return products.filter((item) => {
		let result = false;

		if (Array.isArray(category)) {
			for (let i = 0; i < category.length; i++) {
				if (-1 !== item.category.indexOf(category[i])) {
					result = true;
				}
			}
		} else {
			if (-1 !== item.category.indexOf(category)) result = true;
		}
		return result;
	});
};

/**
 * Get number of products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Integer} count of suitable products
 */
export const getCountByCategory = (products, category) => {
	if (category === "All") return products.length;
	if (category === "Sale") return products.filter((item) => item.discount > 0).length;
	return products.filter((item) => -1 !== item.category.indexOf(category)).length;
};

export const getCategoryBySubCategory = (main_category_filter, category) => {
	if (-1 < findIndex(main_category_filter, (filter) => filter === category.id)) return category.sub_categories;
};

/**
 * Get number of products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Integer} count of suitable products
 */
export const getMainCountByCategory = (products, category) => {
	return products.filter((item) => item.main_category === category).length;
};
/**
 * get total Price of products in cart.
 * @param {Array} cartItems
 * @return {Float} totalPrice
 */
export const getCartTotal = (cartItems) => {
	let total = 0;
	for (let i = 0; i < cartItems.length; i++) {
		total +=
			parseInt(cartItems[i].qty, 10) *
			(cartItems[i].discount && cartItems[i].salePrice < cartItems[i].price
				? cartItems[i].salePrice
				: cartItems[i].price);
	}
	return total;
};

/**
 * get number of products in cart
 * @param {Array} cartItems
 * @return {Integer} numbers of cart items in cartlist
 */
export const getCartCount = (cartItems) => {
	let total = 0;

	for (let i = 0; i < cartItems.length; i++) {
		total += parseInt(cartItems[i].qty, 10);
	}

	return total;
};

/**
 * Get number of products filtered by rating
 * @param {Array} products
 * @param {String} category
 * @return {Integer} number of products filtered by rating
 */
export const getCountByRating = (products, rating) => {
	return products.filter((item) => item.rating === rating).length;
};
