// Get Login
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";

// Get Security Question
export const RECEIVE_SECURITY_QUESTIONS = "RECEIVE_SECURITY_QUESTIONS";

// Get Products
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";

// Get Categories
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";

// Get Seller Categories
export const RECEIVE_SELLER_CATEGORIES = "RECEIVE_SELLER_CATEGORIES";
export const REMOVE_SELLER_CATEGORIES = "REMOVE_SELLER_CATEGORIES";

// Get User Profile
export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE";

// Get Brands
export const RECEIVE_BRANDS = "RECEIVE_BRANDS";
export const RECEIVE_SELLER_BRANDS = "RECEIVE_SELLER_BRANDS";
export const REMOVE_SELLER_BRANDS = "REMOVE_SELLER_BRANDS";

// Get Cart
export const RECEIVE_CART = "RECEIVE_CART";

// Refresh Store
export const REFRESH_STORE = "REFRESH_STORE";

//Quick
export const SHOW_QUICKVIEW = "SHOW_QUICKVIEW";
export const CLOSE_QUICKVIEW = "CLOSE_QUICKVIEW";

// Cart
export const GET_CART = "GET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_ALL_FROM_CART = "REMOVE_ALL_FROM_CART";
export const CHANGE_QTY = "CHANGE_QTY";
export const CHANGE_SHIPPING = "CHANGE_SHIPPING";

// Store front
export const RECEIVE_STORE_FRONT_FAV_LIST = "RECEIVE_STORE_FRONT_FAV_LIST";
export const REMOVE_STORE_FRONT_FAV_LIST = "REMOVE_STORE_FRONT_FAV_LIST";

// WishList
export const TOGGLE_WISHLIST = "TOGGLE_WISHLIST";
export const TOGGLE_DEAL = "TOGGLE_DEAL";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const RESET_COMPARE = "RESET_COMPARE";

//Product Filters
export const SET_FILTER = "SET_FILTER";
export const SORT_BY = "SORT_BY";
export const CATEGORY_FILTER = "CATEGORY_FILTER";
export const MAIN_CATEGORY_FILTER = "MAIN_CATEGORY_FILTER";
export const SEARCH_STRING_FILTER = "SEARCH_STRING_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SIZE_FILTER = "SIZE_FILTER";
export const COLOR_FILTER = "COLOR_FILTER";
export const BRAND_FILTER = "BRAND_FILTER";
export const SHIPPING_TYPE_FILTER = "SHIPPING_TYPE_FILTER";
export const CONDITION_FILTER = "CONDITION_FILTER";
export const PRICE_FILTER = "PRICE_FILTER";
export const RATING_FILTER = "RATING_FILTER";

//Newsletter
export const HIDE_NEWSLETTER_MODAL = "HIDE_NEWSLETTER_MODAL";

// Modal
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const REMOVE_NEWSLETTER = "REMOVE_NESLETTER";

// add - edit product
export const ADD_EDIT_PRODUCT = "ADD_EDIT_PRODUCT";
export const REMOVE_PRODUCT_DETAILS = "REMOVE_PRODUCT_DETAILS";

//
export const EDIT_PROJECT = "EDIT_PROJECT";
export const REMOVE_EDIT_PROJECT = "REMOVE_EDIT_PROJECT";
export const RECEIVE_STORE = "RECEIVE_STORE";
export const REMOVE_STORE = "REMOVE_STORE";
export const UPDATE_STORE = "UPDATE_STORE";

//broker
export const UPDATE_LISTING_SIDEBAR = "UPDATE_LISTING_SIDEBAR";
export const REMOVE_LISTING_SIDEBAR = "REMOVE_LISTING_SIDEBAR";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const ADD_OPEN_DEAL = "ADD_OPEN_DEAL";
export const REMOVE_OPEN_DEAL = "REMOVE_OPEN_DEAL";
export const ADD_OPEN_OFFER = "ADD_OPEN_OFFER";
export const REMOVE_OPEN_OFFER = "REMOVE_OPEN_OFFER";
export const ADD_CHAT_WITH = "ADD_CHAT_WITH";
export const REMOVE_CHAT_WITH = "REMOVE_CHAT_WITH";
