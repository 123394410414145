import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BLOG_URL } from "../../config";
import { Button, Form, Input } from "antd";
import { newsletter } from "../../api/news-letter/news-letter";

function Footer(props) {
	const { userData } = props;
	const [loader, setLoader] = useState(false);

	const [form] = Form.useForm();

	const onSubmit = (values) => {
		setLoader(true);
		var token = userData.data && userData.data.auth && userData.data.auth.access_token;
		const email = values.email;
		newsletter(
			email,
			(data) => {
				setLoader(false);
			},
			token
		);
		setTimeout(() => {
			form.resetFields();
		}, 1500);
	};

	return (
		<>
			<footer className="footer">
				<div className="footer-middle">
					<div className="container">
						<div className="row">
							<div className="col-sm-4 col-lg-3 col-12">
								<div className="widget widget-about">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/header-logo.svg`}
										className="footer-logo"
										alt="Footer Logo"
										width="140"
										height=""
									/>
									<div className="mb-2">Join the Sunhub community, your one-stop shop for all things solar. Buy, Sell & Trade solar panel equipment.</div>
									<div className="social-icons social-icons-color">
										<span className="social-label">Follow Us:</span>
										<a
											href="https://www.facebook.com/Sunhubsolar"
											className="social-icon"
											rel="noopener noreferrer"
											title="Facebook"
											target="_blank">
											<i className="fab fa-facebook-square"></i>
										</a>
										<a
											href="https://www.linkedin.com/company/sunhub/"
											className="social-icon"
											rel="noopener noreferrer"
											title="Linkedin"
											target="_blank">
											<i className="fab fa-linkedin"></i>
										</a>

										<a
											href="https://www.instagram.com/sunhubsolar/"
											className="social-icon"
											rel="noopener noreferrer"
											title="Instagram"
											target="_blank">
											<i className="fab fa-instagram-square"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-sm-3 col-lg-2 col-4">
								<div className="widget">
									<h4 className="widget-title">Solar Products</h4>
									<ul className="widget-list mb-2">
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/Solar-Panels`.toLowerCase()}>Panels</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/Solar-Inverters`.toLowerCase()}>Inverters</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/Solar-Batteries`.toLowerCase()}>Batteries</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/diy-kits-&-equipment`.toLowerCase()}>Kits</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/Solar-Lights`.toLowerCase()}>Light</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/shop/product/EV-Chargers`.toLowerCase()}>EV Charger</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-sm-3 col-lg-2 col-4">
								<div className="widget widget-newsletter">
									<h4 className="widget-title">Resources</h4>
									<ul className="widget-list">
										<li>
											<Link to={`${process.env.PUBLIC_URL}/about-sunhub`}>About Sunhub</Link>
										</li>
										<li>
											<a
												href={BLOG_URL}
												target="blank">
												Blogs
											</a>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/faq`}>FAQ's</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/contact-us`}
												className="show">
												Contact Us
											</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/custom-order-fulfillment`}>Custom Order</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/custom-order-listing`}>Custom Order Listing</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/order-status`}>Order Status</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/trader/find`}>Shop By Watts</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/events`}>Events</Link>
										</li>
										<li>
											<a
												href="https://forum.sunhub.com/"
												target="blank">
												Forum
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-sm-2 col-lg-2 col-4">
								<div className="widget">
									<h4 className="widget-title">Policies</h4>
									<ul className="widget-list mb-2">
										<li>
											<Link to={`${process.env.PUBLIC_URL}/terms-of-use`}>Terms of Use</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/terms-of-sale`}>Terms of Sale</Link>
										</li>
										<li>
											<Link to={`${process.env.PUBLIC_URL}/privacy-notice`}>Privacy Notice</Link>
										</li>

										<li>
											<Link to={`${process.env.PUBLIC_URL}/buyer-terms`}>Solar Trader Buyer Terms</Link>
										</li>

										<li>
											<Link to={`${process.env.PUBLIC_URL}/seller-terms`}>Solar Trader Seller Terms</Link>
										</li>
									</ul>
								</div>
							</div>
							{/* offset-md-4 offset-sm-2 offset-lg-0 */}
							<div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
								<div className="widget">
									<div className="give-us-call">
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/call-us.jpg`}
											alt="Call Us"
											className="call-icon"
										/>
										<h4>Got Question? Call us 24/7</h4>
										<a
											className="call-us"
											href="tel:216-800-9300">
											(216)800-9300
										</a>
										<small>Monday - Friday: 8:00 - 21:00</small>
									</div>
								</div>
								<div className="newsletter-footer">
									<h5 className="cta-title">Newsletter</h5>
									<p className="cta-desc">Sign up to stay updated on new products, prices and solar specials!</p>
									<Form
										form={form}
										layout="horizontal"
										onFinish={onSubmit}
										autoComplete="off">
										<Form.Item
											name="email"
											rules={[
												{
													required: true,
													message: "Please enter your email!",
												},
											]}>
											<Input
												size="large"
												type="email"
												placeholder="Enter your Email Address."
											/>
										</Form.Item>
										<Button
											className="btn btn-primary"
											size="large"
											loading={loader ? true : false}
											htmlType="submit">
											Subscribe
										</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="container">
						<p className="footer-copyright">Copyright © {new Date().getFullYear()} Sunhub. All Rights Reserved.</p>
						<div className="social-icons social-icons-color">
							<ul className="list-inline mb-0">
								<li className="list-inline-item">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/svg/master-card.svg`}
										className=""
										alt="Master Card"
									/>
								</li>
								<li className="list-inline-item">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/svg/visa-card.svg`}
										className=""
										alt="Visa Card"
									/>
								</li>
								<li className="list-inline-item">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/svg/union-pay.svg`}
										className=""
										alt="Union Pay"
									/>
								</li>
								<li className="list-inline-item">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/svg/american-express.svg`}
										className=""
										alt="American Express"
									/>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="sticky-footer hide-on-mobile">
					<ul className="list-inline text-center d-flex">
						<li className="list-inline-item flex-fill ">
							<a href="#.">
								<span className="icon-block">
									<i className="icon-home"></i>
								</span>
								Home
							</a>
						</li>

						<li className="list-inline-item flex-fill ">
							<a href="#.">
								<span className="icon-block">
									<i className="icon-user"></i>
								</span>
								Sign in
							</a>
						</li>
						<li className="list-inline-item flex-fill ">
							<a href="#.">
								<span className="icon-block">
									<i className="icon-shopping-cart"></i>
								</span>
								Cart
							</a>
						</li>
						<li className="list-inline-item flex-fill">
							<a href="#.">
								<span className="icon-block">
									<i className="icon-heart-o"> </i>
								</span>
								Wishlist
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
}

export default Footer;
