import { POST } from "../api";
import { CART_ITEM_LIST } from "../../constants/api";
// import { SOMETHING_WRONG } from "../../lang/en/messages";

export const getCartItems = async (query = {}, onwer_id, callback, token) => {
	query = {
		fields: ["id", "item_uuid", "qty", "price", "shipping_type"],
		with: ["item"],
		owner_id: onwer_id,
	};
	POST(
		CART_ITEM_LIST,
		query,
		function (data) {
			if (data.status && data.status) {
				const cartItems = data.data && data.data;
				const result = cartItems && cartItems.filter((item) => item.status !== "inactive");
				callback(result);
			} else {
				if (data.http_error) {
					// toast.error(data.error);
				} else {
					if (data.errors) {
						for (let i = 0; i < data.errors.length; i++) {
							// toast.error(data.errors[i]);
						}
					} else {
						//   toast.error(SOMETHING_WRONG);
					}
				}
			}
		},
		token
	);
};
