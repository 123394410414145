import { GET_DEAL_LISTING_SIDEBAR } from "../../constants/api";
import ApiService from "../api-service";
const getDealListingSidebar = async () => {
	const response = await ApiService.get(GET_DEAL_LISTING_SIDEBAR);
	if (response && response.success) {
		let data = response.data;
		return data;
	}
	throw new Error("Deal Listing sidebar not found!");
};
export default getDealListingSidebar;
