import gtag from "./gtag";
//add to cart
export function GA_addToCart(product, qty) {
	gtag("event", "add_to_cart", {
		items: [
			{
				id: product.id,
				name: product.name,
				list_name: "Search Results",
				brand: product.brand_id,
				category: product.main_category,
				variant: product.condition,
				list_position: 2,
				quantity: qty,
				price: product.salePrice,
			},
		],
	});
}

//remove from cart
export function GA_removeFromCart(product) {
	gtag("event", "remove_from_cart", {
		items: [
			{
				id: product.id,
				name: product.name,
				list_name: "Search Results",
				brand: product.brand_id,
				category: product.main_category,
				variant: product.condition,
				list_position: 1,
				quantity: product.qty,
				price: product.salePrice,
			},
		],
	});
}

// begin checkout
export function GA_beginCheckout(cartlist) {
	gtag("event", "begin_checkout", {
		items: cartlist.map((item) => {
			return {
				id: item.id,
				name: item.name,
				list_name: "----",
				brand: "SunHub",
				category: item.main_category,
				variant: item.condition,
				list_position: 1,
				quantity: item.qty,
				price: item.salePrice,
			};
		}),
		coupon: "",
	});
}

//purchase
export function GA_purchase(data) {
	gtag("event", "purchase", {
		transaction_id: data.data.id,
		affiliation: "sunhub store",
		value: data.data.total_amount,
		currency: "USD",
		tax: data.data.tax,
		shipping: data.data.shipping_cost,
		items: data.data.order_items.map((item, index) => {
			return {
				id: item.item_id,
				name: item.name,
				list_name: "----",
				brand: "SunHub",
				category: data.data.items[index].category_id,
				variant: "Black",
				list_position: 1,
				quantity: item.qty,
				price: item.price,
			};
		}),
	});
}

//login
export function GA_login() {
	gtag("event", "login", { method: "sunhub" });
}

//logout
export function GA_logout() {
	gtag("event", "logout", { method: "sunhub" });
}
